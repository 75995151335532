.options {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;

    .option {
        padding: 15px 20px;
        border: 1px solid var(--border-color);
        border-radius: 20px;
        cursor: pointer;
        transition: ease filter .3s;
        text-align: center;

        &:hover {
            filter: brightness(130%);
        }
    }
}

.scoreInput {
    width: 100%;
}

.scoreSubmit {
    width: 100%;
    margin-top: 10px;
}
