.menuModal {
  button {
    display: block;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
