.statisticsTable {
  width: 100%;

  th {
    text-align: left;
    padding-right: 10px;
    color: var(--primary-color);
    font-weight: bold;
    padding-bottom: 10px;
  }
}
