.menu {
    display: flex;
    margin-bottom: 10px;

    .menuButton {
        flex: 1;
    }

    button {
        border-radius: 0;
        border-right: 1px solid white;

        &:last-of-type {
            border-right: 0;
        }
    }
}

.scoreblok {
  display: flex;
  max-width: 100vw;
  overflow-x: scroll;

  .scoreblok-column {
    &.title {
      font-weight: bold;
      white-space: nowrap;
      position: sticky;
      left: 0;
      background-color: var(--background);
      color: var(--primary-color);

      .scoreblok-row {
        text-align: right;
      }
    }

    .scoreblok-row {
      padding: 5px;
      border: 1px solid var(--border-color);
      height: 2em;
      text-align: center;

      &.missed {
        height: 2rem;
        font-size: 0;
        background: linear-gradient(to top left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) calc(50% - 0.8px), rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) calc(50% + 0.8px), rgba(0, 0, 0, 0) 100%), linear-gradient(to top right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) calc(50% - 0.8px), rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) calc(50% + 0.8px), rgba(0, 0, 0, 0) 100%);

        @media (prefers-color-scheme: dark) {
          background: linear-gradient(to top left, rgba(164,164,164, 0) 0%, rgba(164,164,164, 0) calc(50% - 0.8px), rgba(164,164,164, 1) 50%, rgba(164,164,164, 0) calc(50% + 0.8px), rgba(164,164,164, 0) 100%), linear-gradient(to top right, rgba(164,164,164, 0) 0%, rgba(164,164,164, 0) calc(50% - 0.8px), rgba(164,164,164, 1) 50%, rgba(164,164,164, 0) calc(50% + 0.8px), rgba(164,164,164, 0) 100%);
        }
      }

      &.header {
        font-weight: bold;
        color: var(--primary-color);
      }

      button {
        white-space: nowrap;
      }
    }
  }
}
