@import './reset';
@import './variables';

* {
  box-sizing: border-box;
}

body {
  font-family: 'Nunito', sans-serif;
  color: var(--text-color);
  background-color: var(--background);
}

button, .button {
  border-radius: 20px;
  background-color: var(--primary-color);
  color: white;
  border: 2px solid var(--primary-color);
  padding: 10px 25px;
  margin: 0;

  &.secondary {
    background-color: white;
    color: var(--primary-color);
  }
}
