$primaryColor: #4a4adb;
$background: white;
$textColor: black;

:root {
  --primary-color: #4a4adb;
  --background: white;
  --text-color: black;
  --border-color: black;

  @media (prefers-color-scheme: dark) {
    --primary-color: #4a4adb;
    --background: #212121;
    --text-color: #a4a4a4;
    --border-color: #656565;
  }
}
